import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible'
import API from './utils/api'

// 全局挂载 API
const app = createApp(App)
app.config.globalProperties.$api = API

// 这样在任何组件中都可以通过 this.$api 访问 API 接口
// const url = this.$api.user.getUserInfo
app.use(store).use(router).use(Vant).mount('#app')
