// API 接口统一管理模块
const API = {
  // 账单相关接口
  bill: {
    // 获取账单汇总
    get_total_by_date: '/keep_accounts/get_total_by_date',
    get_total_by_issues: '/keep_accounts/get_total_by_issues'
  }
}

export default API
