<template>
  <div id="app">
    <keep-alive exclude="Search,SearchResult,Detail">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">

</style>
